export function elementStyleToHTMLStyle(style: Partial<CSSStyleDeclaration>) {
  if (!style) return undefined;
  let str = "";
  function convert(str: string) {
    return str.replace(/([a-z0-9])([A-Z])/g, "$1-$2").toLowerCase();
  }
  for (let key in style) {
    str = `${str}${convert(key)}: ${style[key]};`;
  }
  return str;
}
